<template>
  <div v-if="access.length">
    <v-layout wrap>
      <v-flex
        xs12
        pt-0
        pb-0
      >
        <card-billing-term :access="access">
        </card-billing-term>
      </v-flex>
      <v-flex
        xs12
        pt-0
        pb-0
      >
        <card-payment-term :access="access">
        </card-payment-term>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

import CardPaymentTerm from './payment-term/card.vue'
import CardBillingTerm from './billing-term/card.vue'

export default {
  components: {
    CardBillingTerm,
    CardPaymentTerm,
  },
  data () { return {
    // data
    access: [],
  }},
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),
  },
  methods: {
    // vuex
    ...mapActions('app', ['setMainTitle']),

    // init
    async init() {
      await this.refreshData()
    },

    // function
    async refreshData() {
      const requestAdminAccess = this.requestAdminAccess()
      await requestAdminAccess

      if (!this.access.length) {
        this.$router.push('/')
      }
    },

    // axios
    async requestAdminAccess () {
      try {
        const res = await xPost(
            'admin',
            'access',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            
            this.access = []
            for (const key in res.data.sap) {
              if (!!res.data.sap[key]) { this.access.push(key) }
            }
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get admin access]')
        // console.log(err)
      }
    },
  },
  async beforeMount () {
    this.setMainTitle('Billing/Payment Term Setting')
    let init = await this.init()
  }
}
</script>

<style>

</style>