<template>
  <material-app-card
    id="admin-soldto-sap-list"
    color="primary"
    icon="mdi-file-document"
    title="Standard Payment Terms x Clients"
  >
    <template v-slot>
      <div class="d-flex flex-row mb-4">
        <v-spacer />
        <v-spacer />
        <v-text-field
          v-model="table.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        class="mb-2"
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :no-data-text="isSapLoading ? 'Loading' : 'No Data'"
        @update:pagination="tableUpdatePage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              v-for="(ho, hi) in table.headers"
              :key="'sap-table-item-' + hi + '-key-' + ho.value"
              :style="{
                'text-align': ho.config['text-align'] || 'left',
                ...ho.config['width'] ? { width: ho.config['width'], minWidth: ho.config['width'] } : {},
              }"
            >
              <template v-if="ho.config['select'] && access.includes(ho.config['access']) && table.editing.find(el => el.itemKey === item.itemKey && el.key === ho.value)">
                <div class="d-flex flex-row align-center">
                  <div class="flex-grow" style="margin-top:-16px">
                    <v-select
                      block
                      v-model="item[ho.value]"
                      :items="getSelectItems(ho.config['select']['list'], item[ho.value])"
                      item-value="value"
                      item-text="text"
                      hide-details
                    >
                    </v-select>
                  </div>
                  <div style="margin-left:16px;width:84px;min-width:84px;max-width:84px;">
                    <v-btn
                      :disabled="!checkSelectValue(ho.config['select']['list'], item[ho.value])"
                      icon textsmall class="ma-0"
                      @click="saveEditTableItemValue(item.itemKey, ho.value)"
                    >
                      <v-icon color="primary">mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn
                      icon textsmall class="ma-0"
                      @click="cancelEditTableItemValue(item.itemKey, ho.value)"
                    >
                      <v-icon color="primary">mdi-restore</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-else>
                <span>{{ item[ho.value] || '' }}</span>
                <v-btn
                  v-if="ho.config['select'] && access.includes(ho.config['access'])"
                  icon textsmall class="ml-1 mr-0 my-0"
                  @click="editTableItemValue(item.itemKey, ho.value, item[ho.value])"
                >
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </material-app-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

export default {
  props: {
    access: {
      type: Array,
      require: true,
    }
  },
  data () { return {
    // status
    isSapLoading: true,

    // data
    table: {
      headers: [],
      items: [],

      editing: [],
      saving: [],

      search: '',
    },

    // selections
    select: {
      payments: [],
    },
  }},
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),
  },
  methods: {
    // init
    async init() {
      await this.refreshData()
    },

    // actions
    tableUpdatePage () {
      this.cancelAllEditTableItemValue()
    },
    editTableItemValue(itemKey, key, origin) {
      this.table.editing.push({ itemKey, key, origin: JSON.stringify(origin) })
    },
    async saveEditTableItemValue(itemKey, key) {
      const i = this.table.editing.findIndex(el => el.itemKey === itemKey && el.key === key)
      if (i === -1) { return }
      this.table.saving.push({ itemKey, key })
      if (await this.postSaveEditTableItemValue(itemKey, key)) {
        this.closeEditTableItemValue(itemKey, key)
        this.$eventBus.$emit('snackbar', { text: 'Save Successfully', type: 'success' })
      } else {
        this.$eventBus.$emit('snackbar', { text: 'Failed', type: 'error' })
      }
      this.table.saving = this.table.editing.filter(el => el.itemKey !== itemKey || el.key !== key)
    },
    cancelEditTableItemValue(itemKey, key) {
      const i = this.table.editing.findIndex(el => el.itemKey === itemKey && el.key === key)
      if (i === -1) { return }
      const j = this.table.items.findIndex(el => el.itemKey === itemKey)
      if (j === -1) { return }
      this.table.items[j][key] = JSON.parse(this.table.editing[i].origin)
      this.closeEditTableItemValue(itemKey, key)
    },
    closeEditTableItemValue (itemKey, key) {
      this.table.editing = this.table.editing.filter(el => el.itemKey !== itemKey || el.key !== key)
      this.table.saving = this.table.saving.filter(el => el.itemKey !== itemKey || el.key !== key)
    },
    cancelAllEditTableItemValue () {
      for (const item of this.table.editing) {
        const i = this.table.items.findIndex(el => el.itemKey === item.itemKey)
        if (i === -1) { continue }
        this.table.items[i][item.key] = JSON.parse(item.origin)
      }
      this.table.editing = []
      this.table.saving = []
    },

    // function
    async refreshData() {
      await this.getSap()
    },
    checkSelectValue (key, value) {
      return this.select[key] instanceof Array && !!this.select[key].find(el => el.value === value)
    },
    getSelectItems (key, value) {
      return this.select[key] instanceof Array
        ? [
          ...this.checkSelectValue(key, value)
            ? []
            : [{
                value: value,
                text: value + ' (Retired)',
                disabled: true,
              }],
          ...this.select[key],
        ]
        : []
    },

    // axios
    async getSap(loading) {
      loading = loading || false
      try {
        if (loading) { this.isSapLoading = true }
        const res = await xPost(
            'admin',
            'saps',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            if (res.data.table) {
              this.table.headers = res.data.table
            }
            if (res.data.data) {
              const keys = this.table.headers
                .filter(el => !!el.config.key)
                .map(el => el.value)
              this.table.items = res.data.data.map(el => ({
                ...el,
                itemKey: (item => {
                    let keysObj = {}
                    keys.forEach(key => {
                      keysObj[key] = item[key]
                    });
                    return JSON.stringify(keysObj)
                  })(el)
              }))
            }
            if (res.data.payments) {
              this.select.payments = res.data.payments
            }            
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get saps]')
        console.log(err)
      }
      this.isSapLoading = false
    },
    async postSaveEditTableItemValue (itemKey, key) {
      try {
        const index = this.table.items.findIndex(el => el.itemKey === itemKey)
        if (index === -1) { return }
        // const keys = this.table.headers
        //   .filter(el => !!el.config.key)
        //   .map(el => el.value)
        // let keysObj = {}
        // keys.forEach(key => {
        //   keysObj[key] = this.table.items[index][key]
        // });
        const res = await xPost(
            'admin',
            'sapsavetableitemfield',
            {
              loginStatus: this.loginStatus,
              tabField: key,
              tabData: this.table.items[index][key],
              ...JSON.parse(itemKey)
            }
          )
          if (res.data.status === 200) {
            return true
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [save edit table]')
        console.log(err)
      }
      return false
    },
  },
  async beforeMount () {
    let init = await this.init()
  },
}
</script>

<style>

</style>