<template>
  <material-app-card
    id="admin-soldto-sap-list"
    color="primary"
    icon="mdi-file-document"
    title="Standard Billinng Terms x Material"
  >
    <template v-slot>
      <div class="d-flex flex-row mb-4">
        <v-spacer />
        <v-spacer />
        <v-text-field
          v-model="table.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        class="mb-2"
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :no-data-text="isBillingTermLoading ? 'Loading' : 'No Data'"
        @update:pagination="tableUpdatePage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              v-for="(ho, hi) in table.headers"
              :key="'sap-billing-table-item-' + hi + '-key-' + ho.value"
              :style="{
                'text-align': ho.config['text-align'] || 'left',
                ...ho.config['width'] ? { width: ho.config['width'], minWidth: ho.config['width'] } : {},
                'vertical-align': 'top',
              }"
            >
              <template v-if="ho.config['type'] === 'checkbox'">
                <div class="mt-1">
                  <v-icon
                    v-if="item[ho.value]"
                    color="grey darken-1"
                  >mdi-check-bold</v-icon>
                  <v-icon
                    v-else
                    small
                    color="grey"
                  >mdi-minus</v-icon>
                </div>
              </template>
              <template v-else-if="ho.config['type'] === 'multi-chips'">
                <div class="d-flex flex-row align-center" style="flex-wrap:wrap;">
                  <template v-for="(ct, ci) in item[ho.value]">
                    <div style="flex:0 0 auto!important;" :key="'sap-billing-table-item' + hi + '-chip-' + ci">
                      <v-chip
                        :close="!!table.editing.find(el => el.itemKey === item.itemKey && el.key === ho.value)"
                        @click:close="item[ho.value].splice(ci, 1)"
                      >{{ item[ho.value][ci] }}</v-chip>
                    </div>
                  </template>
                  <template v-if="ho.config['edit']">
                    <v-btn
                      v-if="access.includes(ho.config['access']) && !table.editing.find(el => el.itemKey === item.itemKey && el.key === ho.value)"
                      icon textsmall class="ml-1 mr-0 my-0"
                      style="flex:0 0 auto!important;"
                      @click="editTableItemValue(item.itemKey, ho.value, item[ho.value])"
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                    <template v-else>
                      <template v-if="table.adding.find(el => el.itemKey === item.itemKey && el.key === ho.value)">
                        <div class="d-flex flex-row align-center" style="width:272px;min-width:272px;max-width:272px;">
                          <div style="width:200px;margin-top:-16px;margin-left:16px;margin-right:8px;">
                            <v-text-field
                              hide-details
                              placeholder="Please input Material ID"
                              v-model="table.adding.find(el => el.itemKey === item.itemKey && el.key === ho.value).value"></v-text-field
                            >
                          </div>
                          <div style="width:84px;min-width:84px;max-width:84px;">
                            <v-btn
                              icon textsmall class="ma-0"
                              :disabled="!table.adding.find(el => el.itemKey === item.itemKey && el.key === ho.value).value"
                              @click="submitAddTableItemValueItem(item.itemKey, ho.value)"
                            >
                              <v-icon small color="primary">mdi-check</v-icon>
                            </v-btn>
                            <v-btn
                              icon textsmall class="ma-0"
                              @click="cancelAddTableItemValueItem(item.itemKey, ho.value)"
                            >
                              <v-icon small color="primary">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </template>
                      <v-btn
                        v-else
                        icon textsmall class="ma-0"
                        style="flex:0 0 auto!important;"
                        @click="addTableItemValueItem(item.itemKey, ho.value)"
                      >
                        <v-icon color="primary">mdi-plus</v-icon>
                      </v-btn>
                      <span style="margin-left:4px;">
                        <div style="width:84px;min-width:84px;max-width:84px;">
                          <v-btn
                            icon textsmall class="ma-0"
                            @click="saveEditTableItemValue(item.itemKey, ho.value)"
                          >
                            <v-icon color="primary">mdi-content-save</v-icon>
                          </v-btn>
                          <v-btn
                            icon textsmall class="ma-0"
                            @click="cancelEditTableItemValue(item.itemKey, ho.value)"
                          >
                            <v-icon color="primary">mdi-restore</v-icon>
                          </v-btn>
                        </div>
                      </span>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="mt-2">{{ item[ho.value] || '' }}</div>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </material-app-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

export default {
  props: {
    access: {
      type: Array,
      require: true,
    }
  },
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),
  },
  data () { return {
    // status
    isBillingTermLoading: true,

    // data
    table: {
      headers: [],
      items: [],

      editing: [],
      adding: [],
      saving: [],

      search: '',
    },
  }},
  methods: {
    // init
    async init() {
      await this.refreshData()
    },

    // actions
    tableUpdatePage () {
      this.cancelAllEditTableItemValue()
    },
    editTableItemValue(itemKey, key, origin) {
      this.table.editing.push({ itemKey, key, origin: JSON.stringify(origin) })
    },
    async saveEditTableItemValue(itemKey, key) {
      const i = this.table.editing.findIndex(el => el.itemKey === itemKey && el.key === key)
      if (i === -1) { return }
      this.table.saving.push({ itemKey, key })
      if (await this.postSaveEditTableItemValue(itemKey, key)) {
        this.closeEditTableItemValue(itemKey, key)
        this.$eventBus.$emit('snackbar', { text: 'Save Successfully', type: 'success' })
      } else {
        this.$eventBus.$emit('snackbar', { text: 'Failed', type: 'error' })
      }
      this.table.saving = this.table.editing.filter(el => el.itemKey !== itemKey || el.key !== key)
    },
    cancelEditTableItemValue(itemKey, key) {
      const i = this.table.editing.findIndex(el => el.itemKey === itemKey && el.key === key)
      if (i === -1) { return }
      const j = this.table.items.findIndex(el => el.itemKey === itemKey)
      if (j === -1) { return }
      this.table.items[j][key] = JSON.parse(this.table.editing[i].origin)
      this.closeEditTableItemValue(itemKey, key)
    },
    closeEditTableItemValue (itemKey, key) {
      this.table.editing = this.table.editing.filter(el => el.itemKey !== itemKey || el.key !== key)
      this.table.saving = this.table.saving.filter(el => el.itemKey !== itemKey || el.key !== key)
      this.closeAddTableItemValueItem(itemKey, key)
    },
    cancelAllEditTableItemValue () {
      for (const item of this.table.editing) {
        const i = this.table.items.findIndex(el => el.itemKey === item.itemKey)
        if (i === -1) { continue }
        this.table.items[i][item.key] = JSON.parse(item.origin)
      }
      this.table.editing = []
      this.table.saving = []
      this.table.adding = []
    },
    addTableItemValueItem(itemKey, key) {
      this.table.adding.push({ itemKey, key, value: '' })
    },
    submitAddTableItemValueItem(itemKey, key) {
      const i = this.table.adding.findIndex(el => el.itemKey === itemKey && el.key === key)
      if (i === -1) { return }
      const j = this.table.items.findIndex(el => el.itemKey === itemKey)
      if (j === -1) { return }
      this.table.items[j][key].push(this.table.adding[i].value)
      this.closeAddTableItemValueItem(itemKey, key)
    },
    closeAddTableItemValueItem(itemKey, key) {
      this.table.adding = this.table.saving.filter(el => el.itemKey !== itemKey || el.key !== key)
    },
    cancelAddTableItemValueItem(itemKey, key) {
      this.closeAddTableItemValueItem(itemKey, key)
    },

    // function
    async refreshData() {
      await this.getBillingTerms()
    },
    checkSelectValue (key, value) {
      return this.select[key] instanceof Array && !!this.select[key].find(el => el.value === value)
    },
    getSelectItems (key, value) {
      return this.select[key] instanceof Array
        ? [
          ...this.checkSelectValue(key, value)
            ? []
            : [{
                value: value,
                text: value + ' (Retired)',
                disabled: true,
              }],
          ...this.select[key],
        ]
        : []
    },

    // axios
    async getBillingTerms(loading) {
      loading = loading || false
      try {
        if (loading) { this.isBillingTermLoading = true }
        const res = await xPost(
            'admin',
            'billingterms',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            if (res.data.table) {
              this.table.headers = res.data.table
            }
            if (res.data.data) {
              const keys = this.table.headers
                .filter(el => !!el.config.key)
                .map(el => el.value)
              this.table.items = res.data.data.map(el => ({
                ...el,
                itemKey: (item => {
                    let keysObj = {}
                    keys.forEach(key => {
                      keysObj[key] = item[key]
                    });
                    return JSON.stringify(keysObj)
                  })(el),
                ...(() => {
                  let ret = {}
                  this.table.headers.forEach(el2 => {
                    switch (el2.config.type) {
                      case 'checkbox':
                        ret[el2.value] = !!parseInt(el[el2.value])
                        break;
                    
                      case 'multi-chips':
                        ret[el2.value] = JSON.parse(el[el2.value] || '[]')
                          // .map(el3 => ({
                          //   value: el3,
                          //   text: el3,
                          // }))
                        break;
                    
                      default:
                        break;
                    }
                  })
                  return ret
                })(),
              }))
            }            
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get billing terms]')
        console.log(err)
      }
      this.isBillingTermLoading = false
    },
    async postSaveEditTableItemValue (itemKey, key) {
      try {
        const index = this.table.items.findIndex(el => el.itemKey === itemKey)
        if (index === -1) { return }
        let tabData = this.table.items[index][key]
        switch (this.table.headers.find(el => el.value === key).config.type) {
          case 'checkbox':
            tabData = tabData ? 1 : null
            break;
        
          case 'multi-chips':
            tabData = JSON.stringify(tabData || [])
            break;
        
          default:
            break;
        }
        const res = await xPost(
            'admin',
            'billingtermsavetableitemfield',
            {
              loginStatus: this.loginStatus,
              tabField: key,
              tabData,
              ...JSON.parse(itemKey)
            }
          )
          if (res.data.status === 200) {
            return true
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [save edit table]')
        console.log(err)
      }
      return false
    },
  },
  async beforeMount () {
    let init = await this.init()
  },
}
</script>

<style>

</style>